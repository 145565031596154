body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.HomePage {
  height: 100%;
  width: 100%;
  background-color: darkgrey;
  flex: auto;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.displayBox {
  border: 3px;
  border-style: inset;
  margin: 5px;
}
.detailTitle {
  color: darkslategray;
  border-bottom: 3px;
  border-style: outset;
  font-size: large;
}
.detailImgDiv {
  margin: 1%;
  text-align:center;
}
.detailImageLabel {
  font-size: small;
}
.detailImage {
  height: auto;
  max-width: 100%;
}
.detailText {
  font-size: medium;
  margin: 10px;
  color: darkslategray;
  border-bottom: 3px;
  padding: 1%;
  border-style: inset;
  background-color: lightgray;
}
.detailDetailLol {
  margin-left: 10px;
}

.detailBtn {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  border-bottom: 3px;
  padding: 1%;
  border-style: outset;
  margin: 1%;
}